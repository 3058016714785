import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_upload_outlined = _resolveComponent("upload-outlined")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_divider, {
      orientation: "left",
      style: {"color":"#888"}
    }, {
      default: _withCtx(() => [
        _createTextVNode("作品#" + _toDisplayString(_ctx.index + 1), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_form, {
      "label-col": _ctx.labelCol,
      "wrapper-col": _ctx.wrapperCol,
      onChange: _cache[3] || (_cache[3] = () => _ctx.update(_ctx.formState))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          label: "作品缩略图",
          rules: [{ required: _ctx.index === 0 }]
        }, {
          default: _withCtx(() => [
            (_ctx.formState.previewUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("img", {
                    src: _ctx.formState.previewUrl,
                    alt: "社团logo",
                    style: {"max-width":"45%"}
                  }, null, 8, _hoisted_2),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formState.previewFile.name), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                (_ctx.formState.previewUrl && _ctx.index !== 0)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      onClick: _ctx.clearFile,
                      danger: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_delete_outlined),
                        _createTextVNode(" 删除文件 ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_upload, {
                  "show-upload-list": false,
                  onChange: _ctx.previewFileChange,
                  accept: "image/jpeg,image/png",
                  capture: null,
                  customRequest: ()=>{}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_upload_outlined),
                        (_ctx.formState.previewUrl)
                          ? (_openBlock(), _createBlock(_component_a_typography_text, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode("重新上传")
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_a_typography_text, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode("上传")
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onChange", "customRequest"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["rules"]),
        _createVNode(_component_a_form_item, {
          label: "作品名称",
          rules: [{ required: true }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _ctx.formState.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event)),
              "show-count": "",
              maxlength: _ctx.WORK.NAME.LEN.MAX
            }, null, 8, ["value", "maxlength"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "作品介绍/售卖方式",
          rules: [{ required: _ctx.delegation }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              value: _ctx.formState.abstarct,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.abstarct) = $event)),
              "show-count": "",
              maxlength: _ctx.TEXT.LEN.MAX
            }, null, 8, ["value", "maxlength"])
          ]),
          _: 1
        }, 8, ["rules"]),
        (_ctx.index !== 0)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              danger: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteSelf()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("删除作品")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["label-col", "wrapper-col"])
  ], 64))
}