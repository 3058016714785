import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_image, {
      style: {"cursor":"pointer"},
      preview: { visible: false },
      width: 200,
      src: _ctx.srcs[0] || '',
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true))
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_image_preview_group, {
        preview: { visible: _ctx.visible, onVisibleChange: vis => (_ctx.visible = vis) }
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.srcs, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_a_image, { src: item }, null, 8, ["src"])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["preview"])
    ])
  ], 64))
}