import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_preview_img = _resolveComponent("preview-img")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createBlock(_component_a_descriptions, {
    bordered: "",
    column: 1,
    contentStyle: {textAlign: 'left'},
    labelStyle: {width: '150px'},
    size: "small",
    style: {"text-align":"left"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_descriptions_item, { label: "作品名" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.work.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "缩略图" }, {
        default: _withCtx(() => [
          (_ctx.work.previewFileNames.length)
            ? (_openBlock(), _createBlock(_component_preview_img, {
                key: 0,
                srcs: _ctx.work.previewFileNames.map(filename => `${_ctx.COS_URL}/img/clubs/work/${filename}`)
              }, null, 8, ["srcs"]))
            : (_openBlock(), _createBlock(_component_a_typography_text, {
                key: 1,
                style: {"margin-left":"16px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 未上传 ")
                ]),
                _: 1
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "简介/售卖方式" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.work.abstract || '未填写'), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}