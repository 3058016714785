import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c3cb010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "right" }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton_image = _resolveComponent("a-skeleton-image")!
  const _component_a_skeleton_button = _resolveComponent("a-skeleton-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_skeleton_input = _resolveComponent("a-skeleton-input")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$props.loading)
        ? (_openBlock(), _createBlock(_component_a_skeleton_image, { key: 0 }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "logo",
            src: _ctx.COS_URL + '/img/clubs/logo/' + _ctx.$props.club?.clubLogoName
          }, null, 8, _hoisted_3))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.$props.loading)
        ? (_openBlock(), _createBlock(_component_a_skeleton_button, {
            key: 0,
            style: {"margin":"5px"},
            active: ""
          }))
        : (_openBlock(), _createBlock(_component_a_typography_title, {
            key: 1,
            level: 3,
            class: _normalizeClass(["name", {hideOverflow: !_ctx.$props.showAll}])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.club?.clubName), 1)
            ]),
            _: 1
          }, 8, ["class"])),
      (_ctx.$props.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_a_skeleton_input, {
              style: {"min-width":"200px","width":"95%","margin":"5px"},
              active: "",
              size: "small"
            }),
            _createVNode(_component_a_skeleton_input, {
              style: {"min-width":"200px","width":"95%","margin":"5px"},
              active: "",
              size: "small"
            })
          ]))
        : (_openBlock(), _createBlock(_component_a_typography_paragraph, {
            key: 3,
            class: _normalizeClass(["abstract", {hideOverflow: !_ctx.$props.showAll}])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$props.club?.clubAbstract), 1)
            ]),
            _: 1
          }, 8, ["class"]))
    ])
  ]))
}