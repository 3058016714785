
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    srcs: Array
  },
  setup () {
    return {
      visible: ref<boolean>(false)
    }
  }
})
