
import { defineComponent, reactive } from 'vue'
import { API_URL } from '@/constants'
import ClubList from '@/components/clubExhibition/ClubList.vue'
import { shuffleArray } from '@/utils'

export interface ClubAbbriv {
  id: number;
  clubLogoName: string;
  clubName: string;
  clubAbstract: string;
}

interface State {
  loading: boolean;
  ids: number[]; 
  abbrivs: ClubAbbriv[];
}

export default defineComponent({
  props: {
    maxClubs: {
      type: Number, 
      default: Infinity
    }, 
    randomize: {
      type: Boolean,
    }, 
    hideHeaderfooter: {
      type: Boolean,
    }
  },
  setup(props) {
    const state = reactive<State>({
      loading: true,
      ids: [], 
      abbrivs: [],
    });

    const getIds = async () => {
      try {
        state.loading = true;
        const resp = await fetch(`${API_URL}/club-application/ids`); 
        if (resp.ok) {
          state.ids  = await resp.json(); 
        }
      } catch (err) {
        console.error(err);
      }
    }

    const getClubAbbrivs = async () => {
      try {
        const ids = props.randomize? shuffleArray(state.ids).slice(0, props.maxClubs): state.ids.slice(0, props.maxClubs);
        const resp = await fetch(`${API_URL}/club-application/applications-from-ids?ids=${ids.join(',')}`);
        if (resp.ok) {
          state.abbrivs = await resp.json();
        }
      } catch (err) {
        console.error(err);
      } finally {
        state.loading = false;
      }
    }

    return {
      state,
      getIds,
      getClubAbbrivs
    }
  },
  async mounted() {
    await this.getIds();
    await this.getClubAbbrivs();
  },
  components: {
    ClubList
}
})
