export default class Timer {
  private lastResetAt = Date.now()
  timeSinceLastReset (): number {
    return Date.now() - this.lastResetAt
  }

  reset (): void {
    this.lastResetAt = Date.now()
  }
}
