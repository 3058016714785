import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-251da35a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "exhibition-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_club_card = _resolveComponent("club-card")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.clubs, (club, indx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "club",
          key: indx,
          onClick: () => _ctx.state.modalClub = club
        }, [
          _createVNode(_component_club_card, {
            loading: _ctx.$props.loading,
            club: club
          }, null, 8, ["loading", "club"])
        ], 8, _hoisted_2))
      }), 128))
    ]),
    _createVNode(_component_a_modal, {
      visible: _ctx.state.modalClub,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.modalClub) = $event)),
      title: "参展社团",
      footer: ""
    }, {
      default: _withCtx(() => [
        (_ctx.state.modalClub)
          ? (_openBlock(), _createBlock(_component_club_card, {
              key: 0,
              club: _ctx.state.modalClub,
              showAll: true
            }, null, 8, ["club"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}