import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createBlock(_component_a_typography_paragraph, { style: {"text-align":"left"} }, {
    default: _withCtx(() => [
      _createElementVNode("pre", null, [
        _createTextVNode("\n      "),
        (!_ctx.formState.sdl)
          ? (_openBlock(), _createBlock(_component_a_typography_text, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode("\n      摊位费：              " + _toDisplayString(_ctx.PRICE.STAND) + ".00¥ * " + _toDisplayString(_ctx.formState.stands) + " = " + _toDisplayString(_ctx.formState.stands * _ctx.PRICE.STAND) + ".00¥\n      ", 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_a_typography_text, { key: 1 }, {
              default: _withCtx(() => [
                _createTextVNode("\n      摊位费(苏东联优惠价)： " + _toDisplayString(_ctx.PRICE.STAND_SDL) + ".00¥ * " + _toDisplayString(_ctx.formState.stands) + " = " + _toDisplayString(_ctx.formState.stands * _ctx.PRICE.STAND_SDL) + ".00¥\n      ", 1)
              ]),
              _: 1
            })),
        _createTextVNode("\n      参展证费：            " + _toDisplayString(_ctx.PRICE.PERMIT) + ".00¥ * " + _toDisplayString(_ctx.formState.extraPermits) + " = " + _toDisplayString((_ctx.formState.extraPermits) * _ctx.PRICE.PERMIT) + ".00¥\n      ========================================\n      合计                             " + _toDisplayString((_ctx.formState.extraPermits) * _ctx.PRICE.PERMIT + (_ctx.formState.sdl? _ctx.formState.stands * _ctx.PRICE.STAND_SDL: _ctx.formState.stands * _ctx.PRICE.STAND)) + ".00¥\n    ", 1)
      ]),
      _createTextVNode(" 价格仅供参考，您现在无需付款。 ")
    ]),
    _: 1
  }))
}