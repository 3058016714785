import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e8fe04c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_navbar = _resolveComponent("navbar")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_banner),
    _createVNode(_component_navbar),
    _createVNode(_component_a_space, { align: "center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          id: _ctx.id,
          class: "under-construction-animation"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_title, { style: {"margin":"15px"} }, {
      default: _withCtx(() => [
        _createTextVNode(" 该页面不存在，请检查网址 ")
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}