import Vector from './Vector'
export default class Rectangle {
    pos: Vector
    size: Vector

    constructor (pos: Vector, size: Vector) {
      this.pos = pos.clone()
      this.size = size.clone()
    }

    static fromNumbers (x: number, y: number, w: number, h: number): Rectangle {
      return new Rectangle(new Vector(x, y), new Vector(w, h))
    }

    equals (rect: Rectangle): boolean {
      return this.pos.equals(rect.pos) && this.size.equals(rect.size)
    }

    clone (): Rectangle {
      return new Rectangle(this.pos.clone(), this.size.clone())
    }

    overlapWith (rect: Rectangle): boolean {
      return this.pos.x < rect.pos.x + rect.size.x && this.pos.x + this.size.x > rect.pos.x &&
        this.pos.y < rect.pos.y + rect.size.y && this.pos.y + this.size.y > rect.pos.y
    }
}
