<template>
  <img v-if="circled" src="static/image/circled-arrow.svg" alt="Circled arrow" :style="{width: size, height: size, filter: filter, transform: rotation(direction)}">
  <img v-else src="static/image/arrow.svg" alt="Arrow" :style="{width: size, height: size, filter: filter, transform: rotation(direction)}">
</template>

<script>
import { Options, Vue } from 'vue-class-component'

const rotation = (s) => {
  switch (s) {
    case 'left': return 'rotate(0turn)';
    case 'up': return 'rotate(0.25turn)';
    case 'right': return 'rotate(0.5turn)';
    case 'down': return 'rotate(0.75turn)';
    default: 
      if (isNaN(s)) {
        return 'rotate(' + s + ')';
      } else {
        return 'rotate(' + s + 'deg)';
      }
  }
}

@Options({
  props: ['direction', 'filter', 'circled', 'size'],
  methods: {
    rotation
  }
})
export default class Arrow extends Vue {}
</script>

<style>

</style>