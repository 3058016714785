<template>
  <div class="venue-info" id="venue-info">
    <div class="venue-wrapper">
    <div class="venue-text">
      <h1 class="section-title" style="color: rgb(0, 51, 153)">场馆信息</h1>
      <h3 class="section-title" style="color: rgb(2, 20, 64)">华信半岛酒店</h3>
      <p class="section-content">
        天津市和平区新华路一号
      </p>
      <p class="section-content">
      天桥幻想百景<br />
        2022年8月6日 09:30 ~ 16:30
        </p>
        <p class="section-content">
        槐南相声专场<br />
        2022年8月6日 18:30 ~ 20:30
        </p>
    </div>
    </div>
    <div class="venue-map">
      <VenueMap />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import VenueMap from '../components/VenueMap.vue'

@Options({
  components: {
    VenueMap
  }
})
export default class VenueInfo extends Vue {}
</script>

<style scoped>
.venue-info {
  position: relative;
  width: 100%;
  height: 40vw;
  margin: 0px;
  background-color: rgba(215, 223, 165, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.venue-wrapper{
 position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 5vw 15vw;
  width: 100%;
  height: 40vw;
}

.venue-text {
  padding: 30px;
  float: left;
  text-align: left;
  color: rgb(2, 20, 64);
  background-color: rgba(242,239,218, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-size: 14px;
}

.section-content{
  font-size: 15px;
}

.venue-map {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 40vw;
  float: left;
  padding: 0px;
}

@media screen and (max-width: 800px) {
  .venue-info{
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .venue-wrapper{
    position: relative;
    height: auto;
    padding: 8vw 8vw;
  }
  .venue-text{
    box-shadow: none;
    padding: 0px;
    background-color: transparent;
  }
  .venue-map {
    position: relative;
    height: 60vw;
  }
}

</style>