
import { Options, Vue } from 'vue-class-component'
import HelloWorld from '../components/HelloWorld.vue' // @ is an alias to /src
import ZaiDongLe from '../components/ZaiDongLe.vue'
import Navbar from '../components/Navbar.vue'
import Banner from '../components/Banner.vue'
import Footer from './Footer.vue'

@Options({
  components: {
    HelloWorld,
    ZaiDongLe,
    Navbar,
    Banner,
    Footer
  },
  created: () => {
    document.title = '槐南故事分享'
  }
})
export default class Minigame extends Vue {}
