
import { Vue, Options } from 'vue-class-component'
const randomLaughter = () => {
  if (Math.random() < 0.1) {
    const laughterShort: HTMLAudioElement = document.getElementById('laughter-short') as HTMLAudioElement
    laughterShort.currentTime = 0
    laughterShort.play()
  } else {
    const laughter: HTMLAudioElement = document.getElementById('laughter') as HTMLAudioElement
    laughter.currentTime = Math.floor(Math.random() * 3) * 3
    laughter.play()
    setTimeout(() => { easeOut(laughter) }, 2500)
  }
}
const easeOut = (audio: HTMLAudioElement) => {
  let volume: number = audio.volume
  volume -= 0.1
  if (volume <= 0) {
    audio.volume = 1
    audio.pause()
  } else {
    audio.volume = volume
    setTimeout(() => { easeOut(audio) }, 50)
  }
}
@Options({
  methods: {
    randomLaughter
  },
  props: ['laugh'],
  data () {
    return {
      logoSrc: 'https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2FHuainanchaguan_Full.png'
    }
  }
})
export default class Banner extends Vue {}
