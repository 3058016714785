
import { UploadChangeParam } from 'ant-design-vue'
import { defineComponent, reactive } from 'vue'

import { WORK, TEXT, MB } from '../constants'

export interface WorkInfo {
  name: string
  abstarct: string
  previewUrl?: string
  previewFile: Blob | null
}

export default defineComponent({
  props: {
    delegation: Boolean,
    index: Number,
    update: Function,
    deleteSelf: Function,
    formData: Object,
    pushError: Function
  },
  updated () {
    this.formState.name = (this.formData as WorkInfo).name
    this.formState.abstarct = (this.formData as WorkInfo).abstarct
    this.formState.previewUrl = (this.formData as WorkInfo).previewUrl
    this.formState.previewFile = (this.formData as WorkInfo).previewFile
  },
  setup (props) {
    const formState = reactive<WorkInfo>({
      name: props.formData?.name,
      abstarct: props.formData?.abstarct,
      previewUrl: props.formData?.previewUrl,
      previewFile: props.formData?.previewFile
    })

    const previewFileChange = (info: UploadChangeParam) => {
      const file = info.file.originFileObj
      if (!file) {
        return
      }
      if (info.file.size && info.file.size > WORK.PREVIEW.SIZE.MAX) {
        if (props.pushError) {
          props.pushError(`作品缩略图不应超过${WORK.PREVIEW.SIZE.MAX / MB}mb.`)
        } else {
          console.error('props.pushError not provided')
        }
        return
      }
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        formState.previewUrl = reader.result as string
      })
      reader.readAsDataURL(file)
      formState.previewFile = file
      if (props.update) {
        props.update(formState)
      } else {
        console.error('props.update not provided')
      }
    }

    const clearFile = () => {
      formState.previewUrl = ''
      formState.previewFile = null
    }

    return {
      formState,
      previewFileChange,
      clearFile,
      labelCol: { // 24格栅格系统，label所占为 x
        xxl: 5, // ≥1600px 响应式栅格
        xl: 8, // ≥1200px 响应式栅格
        lg: 10, // ≥992px 响应式栅格
        md: 12 // ≥768px 响应式栅格
      },
      wrapperCol: { // 24格栅格系统，label后面内容所占为 24-x
        xxl: 19,
        xl: 16,
        lg: 14,
        md: 12
      }
    }
  },
  data () {
    return { WORK, TEXT }
  }
})
