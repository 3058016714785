
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      errors: [] as string[]
    }
  },
  methods: {
    pushError (err: string) {
      this.errors.push(err)
    },
    clearError () {
      this.errors.length = 0
    }
  }
})
