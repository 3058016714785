<template>
  <div class="dodge-container">
    <canvas class="dodge" id="canvas" oncontextmenu="event.preventDefault()" tabindex="-1"></canvas>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component'

export default class DodgeFrame extends Vue {
  mounted() {
    let moduleDeclaration = document.createElement('script')
    moduleDeclaration.text = 'var Module;'
    document.head.appendChild(moduleDeclaration)
    Module = {
      preRun: [],
      postRun: [],
      print: (function() {})(),
      canvas: (function() {
        var canvas = document.getElementById('canvas');
        canvas.addEventListener("webglcontextlost", function(e) { alert('WebGL context lost. You will need to reload the page.'); e.preventDefault(); }, false);

        return canvas;
      })(),
      setStatus: function(text) {},
      totalDependencies: 0,
      monitorRunDependencies: function(left) {}
    };
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'static/Dodge.js')
    externalScript.async = true;
    document.head.appendChild(externalScript)
  }
}
</script>

<style>
.dodge-container {
  width: auto;
  height: 100vh;
}

.dodge {
  margin: none;
  border: none;
  padding: none;
  background-color: rgba(0, 0, 0, 0.0);
}
</style>