import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_alerts = _resolveComponent("alerts")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_club_application_display = _resolveComponent("club-application-display")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_banner),
    _createVNode(_component_nav_bar),
    _createVNode(_component_alerts, { ref: "alertsRef" }, null, 512),
    _createVNode(_component_a_space, {
      size: "large",
      direction: "vertical",
      style: {"max-width":"80%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_typography_title, { style: {"margin-top":"19px","margin-bottom":"0"} }, {
          default: _withCtx(() => [
            _createTextVNode(" 社团申请列表 ")
          ]),
          _: 1
        }),
        _createVNode(_component_a_pagination, {
          current: _ctx.pagination.pageNumber,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination.pageNumber) = $event)),
          "page-size": _ctx.pagination.pageSize,
          "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination.pageSize) = $event)),
          total: _ctx.pagination.totalItems,
          onChange: _ctx.onPageChange
        }, null, 8, ["current", "page-size", "total", "onChange"]),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.state.loading? '加载中': `第
        ${(_ctx.pagination.pageNumber - 1) * _ctx.pagination.pageSize + 1}
         - ${Math.min((_ctx.pagination.pageNumber) * _ctx.pagination.pageSize, _ctx.pagination.totalItems)}
        项，共 ${_ctx.pagination.totalItems} 项`), 1)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            id: `app-${index}`,
            style: {"list-style-type":"none"}
          }, [
            _createVNode(_component_club_application_display, { application: item }, null, 8, ["application"])
          ], 8, _hoisted_1))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}