
import { defineComponent, onMounted, reactive, ref } from 'vue'

import NavBar from '@/components/Navbar.vue'
import Banner from '@/components/Banner.vue'
import ClubApplicationDisplay from '@/components/ClubApplicationDisplay.vue'
import Alerts from '@/components/Alerts.vue'
import { API_URL } from '@/constants'
import { ServerErrorResponse } from '@/types'

interface GetWorkResponse {
  id: number;
  name: string;
  abstract?: string;
  previewFileNames?: string[];
}
export interface GetApplicationResponse {
  referenceCode: string;
  clubLogoName?: string;
  clubName: string;
  clubAbstract?: string;
  clubQQ: number;
  delegation: boolean;
  stands?: number;
  extraPermits?: number;
  sdl?: boolean;
  works: GetWorkResponse[]
  createdAt: number;
  updatedAt: number;
}
export interface GetManyApplicationsResponse {
  applications: GetApplicationResponse[];
  pageNumber: number;
  totalItems: number;
  pageSize: number;
}
interface State {
  secret: string,
  loading: boolean,
}
interface PaginationState {
  pageNumber: number,
  pageSize: number,
  totalItems: number,
}

export default defineComponent({
  setup () {
    const state = reactive<State>({
      secret: '',
      loading: false
    })
    const pagination = reactive<PaginationState>({
      pageNumber: 1,
      pageSize: 10,
      totalItems: 0
    })
    const applications = reactive<GetApplicationResponse[]>([])
    const alertsRef = ref<typeof Alerts>()
    let _err // <- To get rid of the linter error 'Expect assignment saw expression'

    const loadApplications = async () => {
      applications.length = 0
      try {
        state.loading = true
        const res = await fetch(
          `${API_URL}/club-application/get-many?pageNumber=${pagination.pageNumber - 1}&pageSize=${pagination.pageSize}&secret=${state.secret}`
        )
        const body = await res.json()
        if (!res.ok) {
          if (res.status === 401) {
            _err = alertsRef.value?.pushError('访问码错误')
            state.loading = false
            return
          }
          (body as ServerErrorResponse).errors.forEach(err => alertsRef.value?.pushError(err.msg))
          state.loading = false
          return
        }
        sessionStorage.setItem('view-club-application-secret', state.secret)
        const resp: GetManyApplicationsResponse = body
        pagination.pageNumber = resp.pageNumber + 1
        pagination.pageSize = resp.pageSize
        pagination.totalItems = resp.totalItems
        for (const app of resp.applications) {
          applications.push(app)
        }
        state.loading = false
      } catch (err) {
        state.loading = false
        console.error(err)
        _err = alertsRef.value?.pushError('无法连接到服务器，请检查网络设置')
      }
    }
    const getSecret = () => {
      const secretFromStorage = sessionStorage.getItem('view-club-application-secret')
      if (secretFromStorage) {
        state.secret = secretFromStorage
      } else {
        const secretFromPrompt = prompt('该内容受到保护，请输入访问码')
        if (secretFromPrompt) {
          state.secret = secretFromPrompt
        } else {
          _err = alertsRef.value?.pushError('您需要输入访问码来获取此页面内容')
        }
      }
    }
    const onPageChange = (page: number, pageSize: number) => {
      pagination.pageNumber = page
      pagination.pageSize = pageSize
      loadApplications()
    }
    if (_err) {}
    onMounted(() => {
      getSecret()
      if (state.secret) {
        loadApplications()
      }
    })

    return {
      state,
      applications,
      alertsRef,
      loadApplications,
      pagination,
      onPageChange
    }
  },
  components: {
    NavBar, Banner, ClubApplicationDisplay, Alerts
  }
})
