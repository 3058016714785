
import { defineComponent } from 'vue'

import { PRICE } from '../constants'

export default defineComponent({
  props: {
    formState: Object
  },
  data () {
    return {
      PRICE
    }
  }
})
