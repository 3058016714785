
import { defineComponent } from 'vue'

import PreviewImg from '@/components/PreviewImg.vue'
import { COS_URL } from '@/constants'

export default defineComponent({
  props: {
    work: Object
  },
  components: {
    PreviewImg
  },
  setup () {
    return {
      COS_URL
    }
  }
})
