
import {defineComponent, PropType, reactive} from 'vue'
import { ClubAbbriv } from './ClubExhibition.vue'
import ClubCard from './ClubCard.vue'

interface State {
  modalClub: ClubAbbriv | null;
}

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
    }, 
    clubs: {
      type: Object as PropType<ClubAbbriv[]>,
      default: []
    },
    placeholderCount: {
      type: Number,
      default: 4
    }
  },
  setup(props) {
    const state = reactive<State>({
      modalClub: null
    });
    return {
      state
    }
  },
  components: {
    ClubCard
  }
})
