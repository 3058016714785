import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_typography = _resolveComponent("a-typography")!

  return (_openBlock(), _createBlock(_component_a_typography, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_typography_title, null, {
        default: _withCtx(() => [
          _createTextVNode("您的社团申请已提交！")
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_title, { level: 3 }, {
        default: _withCtx(() => [
          _createTextVNode("您的申请号码是: "),
          _createVNode(_component_a_typography_text, {
            type: "danger",
            code: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.referenceCode), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_paragraph, null, {
        default: _withCtx(() => [
          _createTextVNode(" 本网站"),
          _createVNode(_component_a_typography_text, {
            type: "danger",
            strong: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("不会")
            ]),
            _: 1
          }),
          _createTextVNode("帮您储存申请号码，请自行记录。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_paragraph, null, {
        default: _withCtx(() => [
          _createTextVNode(" 用户系统尚未上线，申请号码将是您日后修改申请表/将申请表绑定账号的凭证，请妥善保管。 ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}