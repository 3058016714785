import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  style: {"position":"fixed","z-index":"10","top":"20px","list-style-type":"none","left":"25%"},
  "wrapper-col": {span:24}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: `error#${index}`
      }, [
        _createVNode(_component_a_alert, {
          description: item,
          type: "error",
          "show-icon": "",
          closable: "",
          afterClose: ()=>{_ctx.errors.splice(index, 1)}
        }, null, 8, ["description", "afterClose"])
      ]))
    }), 128))
  ]))
}