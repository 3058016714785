
import { defineComponent, reactive, inject } from 'vue'
import { UploadChangeParam } from 'ant-design-vue'
import { SmoothScrollOptions } from 'vue3-smooth-scroll'

import Navbar from '../components/Navbar.vue'
import Banner from '../components/Banner.vue'
import ClubWork, { WorkInfo } from '../components/ClubWork.vue'
import ClubApplicationSubmitted from '../components/ClubApplicationSubmitted.vue'
import CostCalculator from '../components/CostCalculator.vue'
import { API_URL, TEXT, USER, MB, PRICE, WORK } from '../constants'
import { ServerErrorResponse } from '../types'

interface ClubInfo {
  name: string
  qq: string
  abstract: string
  logoUrl: string
  logoFile: Blob | null
}

interface FormState {
  state: 'filling' | 'loading' | 'submitted'
  referenceCode: string
  delegation: boolean
  stands: number
  extraPermits: number
  sdl: boolean
  provideClubInfo: boolean
  club: ClubInfo
  works: WorkInfo[]
  approvedNotice: boolean
}

interface RequestWorkInfo {
  name: string
  abstract?: string
  previewFiles: number
}

interface RequestTextFields {
  clubQQ: number
  delegation: boolean
  stands: number
  extraPermits: number
  sdl: boolean
  clubName: string
  clubAbstract: string
  works: RequestWorkInfo[]
}

export default defineComponent({
  setup () {
    const formState = reactive<FormState>({
      state: 'filling',
      referenceCode: '',
      delegation: false,
      stands: 1,
      extraPermits: 0,
      sdl: false,
      provideClubInfo: true,
      club: {
        name: '',
        abstract: '',
        logoUrl: '',
        logoFile: null,
        qq: ''
      },
      works: [{
        name: '',
        abstarct: '',
        previewFile: null,
        previewUrl: ''
      }],
      approvedNotice: false
    })

    const logoFileChange = (info: UploadChangeParam) => {
      if (!info.file.originFileObj) {
        return
      }
      if (info.file.size && info.file.size > USER.AVATAR.SIZE.MAX) {
        scrollToElmWithId('club-logo')
        errors.push(`社团logo不应超过${USER.AVATAR.SIZE.MAX / MB}mb.`)
        return
      }
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        formState.club.logoUrl = reader.result as string
      })
      reader.readAsDataURL(info.file.originFileObj)
      formState.club.logoFile = info.file.originFileObj
    }

    const onStandsAmountChange = () => {
      if (formState.stands === 1 && formState.extraPermits > 2) {
        formState.extraPermits = 2
      }
    }

    const errors = reactive<string[]>([])

    const addWork = () => {
      if (formState.works.length === WORK.AMOUNT.MAX) {
        errors.push(`最多提交${WORK.AMOUNT.MAX}项作品`)
        return
      }
      formState.works.push({
        name: '',
        abstarct: '',
        previewUrl: '',
        previewFile: null
      })
    }

    const deleteWorkByIndex = (index: number) => {
      formState.works.splice(index, 1)
    }

    const workInfoUpdateHandler = (index: number) => {
      return (newInfo: WorkInfo) => {
        formState.works[index] = newInfo
      }
    }

    const smoothScroll = inject('smoothScroll') as (arg: SmoothScrollOptions) => void
    const scrollToElmWithId = (id: string) => {
      smoothScroll({
        scrollTo: document.getElementById(id) as Element,
        duration: 500
      })
    }

    const validateInputs = ():FormData | null => {
      const form = new FormData()
      const { club } = formState
      formState.club.name = formState.club.name.trim()
      formState.club.abstract = formState.club.abstract.trim()

      if (!formState.club.name) {
        scrollToElmWithId('club-name')
        errors.push('请填写社团名')
        return null
      }
      if (formState.club.name.length < USER.USERNAME.LEN.MIN) {
        scrollToElmWithId('club-name')
        errors.push(`社团名长度应在${USER.USERNAME.LEN.MIN}-${USER.USERNAME.LEN.MAX}之间`)
        return null
      }
      if (formState.provideClubInfo) {
        if (!(club.name && club.abstract && club.logoUrl && club.logoFile)) {
          scrollToElmWithId('club-info')
          errors.push('请填写社团信息')
          return null
        }
        form.append('club-logo', club.logoFile)
      }
      if (!formState.club.qq) {
        scrollToElmWithId('club-qq')
        errors.push('请填写社团QQ')
        return null
      }
      if (formState.delegation && !formState.approvedNotice) {
        return null
      }
      const works: RequestWorkInfo[] = []
      const nameToIndx: Map<string, number> = new Map()
      for (let i = 0; i < formState.works.length; i++) {
        const work = formState.works[i]
        work.name = work.name.trim()
        work.abstarct = work.abstarct.trim()
        if (!work.name) {
          scrollToElmWithId(`work-${i}`)
          errors.push(`请填写作品#${i + 1}的名称`)
          return null
        }
        if (i === 0 && !(work.previewUrl && work.previewFile)) {
          scrollToElmWithId(`work-${i}`)
          errors.push(`请上传作品#${i + 1}的缩略图`)
          return null
        }
        if (formState.delegation && !work.abstarct) {
          scrollToElmWithId(`work-${i}`)
          errors.push(`请上传作品#${i + 1}的简介/售卖方式`)
          return null
        }
        const seenAt = nameToIndx.get(work.name)
        console.log(seenAt)
        if (seenAt !== undefined) {
          scrollToElmWithId(`work-${i}`)
          errors.push(`作品#${seenAt + 1}与作品#${i + 1}名称重复，请勿重复上传作品`)
          return null
        }
        nameToIndx.set(work.name, i)
        works.push({
          name: work.name,
          abstract: work.abstarct,
          previewFiles: work.previewUrl ? 1 : 0
        })
        if (work.previewFile) {
          form.append('work-preview', work.previewFile)
        }
      }
      const textFields: RequestTextFields = {
        clubName: formState.club.name,
        clubAbstract: formState.club.abstract,
        clubQQ: parseInt(formState.club.qq),
        sdl: formState.sdl,
        stands: formState.stands,
        extraPermits: formState.extraPermits,
        delegation: formState.delegation,
        works: works
      }
      form.append('json', JSON.stringify(textFields))
      return form
    }

    const submit = async () => {
      errors.length = 0
      formState.state = 'loading'
      formState.referenceCode = '123123'
      let form
      try {
        form = validateInputs()
      } catch {
        errors.push('网页错误')
        formState.state = 'filling'
        return
      }
      if (!form) {
        formState.state = 'filling'
        return
      }
      try {
        const res = await fetch(
          `${API_URL}/club-application`, {
            method: 'POST',
            body: form,
            mode: 'cors'
          }
        )
        if (res.status === 500) {
          errors.push('服务器内部错误')
          formState.state = 'filling'
          return
        }
        let resBody
        try {
          resBody = await res.json()
        } catch (err) {
          errors.push('解析服务器响应时出现错误')
          formState.state = 'filling'
          return
        }
        if (!res.ok) {
          (resBody as ServerErrorResponse).errors.forEach(e => errors.push(e.msg))
          formState.state = 'filling'
          return
        }
        formState.referenceCode = resBody.referenceCode
        formState.state = 'submitted'
      } catch (err) {
        errors.push('无法连接到服务器，请检查网络设置')
        console.error(err)
        formState.state = 'filling'
      }
    }

    const sanitizeQQInput = () => {
      formState.club.qq = formState.club.qq.replace(/[^0-9]+/g, '')
    }
    return {
      formState,
      onStandsAmountChange,
      logoFileChange,
      addWork,
      workInfoUpdateHandler,
      deleteWorkByIndex,
      labelCol: { // 24格栅格系统，label所占为 x
        xxl: 5, // ≥1600px 响应式栅格
        xl: 8, // ≥1200px 响应式栅格
        lg: 10, // ≥992px 响应式栅格
        md: 12 // ≥768px 响应式栅格
      },
      wrapperCol: { // 24格栅格系统，label后面内容所占为 24-x
        xxl: 19,
        xl: 16,
        lg: 14,
        md: 12
      },
      errors,
      submit,
      sanitizeQQInput
    }
  },
  components: {
    Navbar,
    Banner,
    ClubWork,
    ClubApplicationSubmitted,
    CostCalculator
  },
  data () {
    return {
      TEXT, USER, PRICE
    }
  }
})
