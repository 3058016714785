import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ac22dbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "club-exhibition-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_club_exhibition = _resolveComponent("club-exhibition")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_banner),
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_club_exhibition, { "hide-headerfooter": "" })
    ]),
    _createVNode(_component_a_typography_title, {
      level: 3,
      style: {"padding-bottom":"20px"}
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 社团信息陆续更新中 ")
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}