
import { defineComponent, ref } from 'vue'

import { GetApplicationResponse } from '@/views/ViewClubApplications.vue'
import PreviewImg from '@/components/PreviewImg.vue'
import ClubWorkDisplay from '@/components/ClubWorkDisplay.vue'
import { COS_URL } from '@/constants'

export default defineComponent({
  props: {
    application: Object
  },
  setup (props) {
    const displayWorks = ref<boolean>(true)
    const _application = props.application as GetApplicationResponse
    return {
      displayWorks,
      COS_URL,
      _application
    }
  },
  components: {
    PreviewImg, ClubWorkDisplay
  }
})
