
import { defineComponent } from 'vue'

import Banner from '@/components/Banner.vue'
import Navbar from '@/components/Navbar.vue'
import ClubExhibition from '@/components/clubExhibition/ClubExhibition.vue'
import Footer from './Footer.vue'

export default defineComponent({
  components: {
    Banner, Navbar, ClubExhibition, Footer
  }
})
