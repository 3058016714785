import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_preview_img = _resolveComponent("preview-img")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_club_work_display = _resolveComponent("club-work-display")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_descriptions, {
      title: _ctx._application.clubName,
      style: {"text-align":"left"},
      bordered: "",
      column: { xxl: 3, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 },
      contentStyle: {textAlign: 'left'},
      labelStyle: {width: '150px'},
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_descriptions_item, {
          label: "申请码",
          contentStyle: {fontFamily: 'Consolas, monospace'}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx._application.referenceCode), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_descriptions_item, {
          label: "社团QQ",
          contentStyle: {fontFamily: 'Consolas, monospace'}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx._application.clubQQ.toString().padStart(10, '0')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_descriptions_item, { label: "参展方式" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx._application.delegation? '委托参展': '亲自参展'), 1)
          ]),
          _: 1
        }),
        (!_ctx._application.delegation)
          ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
              key: 0,
              label: "摊位数量",
              contentStyle: {fontFamily: 'Consolas, monospace'}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx._application.stands), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx._application.delegation)
          ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
              key: 1,
              label: "额外参展证"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx._application.extraPermits || 0), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx._application.delegation)
          ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
              key: 2,
              label: "参加苏东联"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx._application.sdl? '是': '否'), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_a_descriptions_item, {
          label: "社团Logo",
          contentStyle: {width: '200px', padding: 0}
        }, {
          default: _withCtx(() => [
            (_ctx._application.clubLogoName)
              ? (_openBlock(), _createBlock(_component_preview_img, {
                  key: 0,
                  srcs: [`${_ctx.COS_URL}/img/clubs/logo/${_ctx._application.clubLogoName}`]
                }, null, 8, ["srcs"]))
              : (_openBlock(), _createBlock(_component_a_typography_text, {
                  key: 1,
                  style: {"margin-left":"16px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 未上传 ")
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }),
        _createVNode(_component_a_descriptions_item, { label: "社团简介" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx._application.clubAbstract || '未填写'), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_a_space, {
      size: "small",
      direction: "vertical",
      style: {"width":"95%","text-align":"left"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_typography_title, {
          level: 5,
          style: {"margin-top":"5px","margin-bottom":"0"}
        }, {
          default: _withCtx(() => [
            _createTextVNode("作品列表")
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._application.works, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            id: `app-${_ctx._application.referenceCode}-work-${index}`,
            style: {"list-style-type":"none"}
          }, [
            _createVNode(_component_club_work_display, { work: item }, null, 8, ["work"])
          ], 8, _hoisted_1))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { style: {"text-align":"right"} }, {
      default: _withCtx(() => [
        _createTextVNode(" 提交于 " + _toDisplayString(new Date(_ctx._application.createdAt).toLocaleString()), 1)
      ]),
      _: 1
    })
  ], 64))
}