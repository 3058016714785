
import { Vue, Options } from 'vue-class-component'
import Dinosaur from '../ts_modules/dinosaur/Dinosaur'

@Options({
  mounted: () => {
    setTimeout(() => new Dinosaur(document.getElementById('huangtu') as HTMLDivElement)
      , 500)
  },
  data () {
    return {
      starSrc: 'https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2Fstar.svg',
      sunSrc: 'https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2FSun.svg'
    }
  }
})
export default class ZaiDongLe extends Vue {}
