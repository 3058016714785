<template>
  <div>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component';
export default class TicketImportManager extends Vue {
  columns = null;
  data = {

  };
}
</script>

<style>

</style>