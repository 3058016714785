
import { defineComponent, PropType } from 'vue'
import { COS_URL } from '@/constants';

export type ClubInfo = {
  clubLogoName: string;
  clubName: string;
  clubAbstract: string;
}

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    club: {
      type: Object as PropType<ClubInfo | null>,
      default: null
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      COS_URL
    }
  }
})
